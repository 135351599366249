.button {
    padding: 10px;
    margin: 10px;
    color: rgb(205, 205, 205);
    text-decoration: none;
    border-radius: 5px;
    font-family: 'sono';

}

.button:hover {
    background-color: rgba(117, 117, 117, 0.101)
}

.active {
    background-color: rgba(117, 117, 117, 0.101)
}